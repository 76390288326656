import {Box, Heading, HStack, Text, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {isNotNil} from 'ramda';

import {icons} from '../../../../../assets/icons/icons';
import {useCatalogue} from '../../../../../hooks/useCatalogue';

interface HeaderItemProps {
  icon: keyof typeof icons;
  title: string;
  value?: string | null;
  name?: string;
  unit?: string;
}

export function HeaderItem(props: HeaderItemProps) {
  const getLabel = useCatalogue();

  const IconComponent = icons[props.icon];
  const value = getLabel(props.name, props.value);
  const valueWithUnit = isNotNil(props.unit) && isNotNil(value) ? `${value} ${props.unit}` : value;

  return (
    <HStack align="center" spacing={4}>
      <StyledIcon>
        <IconComponent />
      </StyledIcon>
      <VStack spacing={1}>
        <Box>
          <Text size="small">{props.title}</Text>
        </Box>
        <Box>
          <Heading size={3}>{valueWithUnit ?? '?'}</Heading>
        </Box>
      </VStack>
    </HStack>
  );
}

const StyledIcon = styled.div`
  width: 40px;
  height: 40px;
  background: #fee5c8;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
