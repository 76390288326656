import {
  Box,
  getValueByDevice,
  Grid,
  Heading,
  HStack,
  Show,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';

import {Trans} from 'react-i18next';
import {useParams} from 'react-router-dom';

import {suffixTestId, TestIdProps} from 'shared';

import {Flag} from '../../../../../components/Flag/Flag';
import {PieChart} from '../../../../../components/PieChart/PieChart';
import i18n from '../../../../../i18n/i18n';
import {useNavigation} from '../../../components/NavigationContext/NavigationContext';
import {VinDecoderCard} from '../../../components/VinDecoderCard/VinDecoderCard';

const SHOW_COMPARE_FLAG = false;

interface FeaturesCardProps extends TestIdProps {
  featureLevel: number;
  featureLevelDiff: number;
  availableFeatures: string[];
  missingFeatures: string[];
}

export function FeaturesCard(props: FeaturesCardProps) {
  const formatPercentage = useFormatPercentage();
  const device = useDevice();
  const {registerSection} = useNavigation();
  const heading = i18n.t('entity.features.labels.featuresHeading');
  const ref = registerSection(heading);
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';

  return (
    <VinDecoderCard
      icon="sell"
      heading={heading}
      summary={i18n.t('entity.features.labels.featureLevelWithValue', {
        value: formatPercentage(props.featureLevel, 'down'),
      })}
      ref={ref}
      data-testid={suffixTestId('vinDecoderCard', props)}
    >
      <VStack spacing={6}>
        <Text>{i18n.t('entity.features.labels.featuresDescription')}</Text>
        <Text size="small" alternative>
          {i18n.t('entity.features.labels.featureLevel')}
        </Text>
        <Box
          paddingBottom={isPrintForced ? 14 : [0, 14]}
          borderBottom={getValueByDevice(device, undefined, '1px solid')}
          borderColor="palettes.neutral.40.100"
          width="100%"
        >
          <VStack spacing={isPrintForced ? 10 : [5, 10]} align="center">
            <PieChart
              type="half"
              percentage={props.featureLevel * 100}
              maxWidth={84}
              data-testid={suffixTestId('featureLevelChart', props)}
            >
              <VStack spacing={2} align="center" justify="center">
                <Text size="small" alternative>
                  {i18n.t('entity.features.labels.featureLevel')}
                </Text>
                <Heading size={1}>{formatPercentage(props.featureLevel, 'down')}</Heading>
                <Show when={SHOW_COMPARE_FLAG}>
                  <Flag
                    label={formatPercentage(Math.abs(props.featureLevelDiff), 'down')}
                    icon={
                      props.featureLevelDiff > 0
                        ? 'navigation/arrow_upward'
                        : 'navigation/arrow_downward'
                    }
                    severity={props.featureLevelDiff > 0 ? 'success' : 'error'}
                    data-testid={suffixTestId('featureDiff', props)}
                  />
                </Show>
              </VStack>
            </PieChart>
            <Text>
              <Trans
                i18nKey={`entity.features.labels.features${props.featureLevelDiff > 0 ? 'Higher' : 'Lower'}`}
                components={{bold: <strong />}}
                values={{percentage: formatPercentage(props.featureLevelDiff, 'down')}}
              />
            </Text>
          </VStack>
        </Box>
        <Grid columns={isPrintForced ? 2 : [1, 2]} spacing={6}>
          <VStack spacing={6}>
            <Box>
              <Heading size={4} isInline>
                {i18n.t('entity.features.labels.available')}
              </Heading>
              <Heading size={4} alternative isInline>
                {` ${i18n.t('entity.features.labels.attractiveFeatures')}`}
              </Heading>
            </Box>
            <HStack spacing={2} wrap>
              {props.availableFeatures.map((feature) => (
                <Flag
                  key={feature}
                  label={feature}
                  data-testid={suffixTestId('availableFeature', props)}
                />
              ))}
            </HStack>
          </VStack>
          <VStack spacing={6}>
            <Box>
              <Heading size={4} isInline>
                {i18n.t('entity.features.labels.missing')}
              </Heading>
              <Heading size={4} alternative isInline>
                {` ${i18n.t('entity.features.labels.attractiveFeatures')}`}
              </Heading>
            </Box>
            <HStack spacing={2} wrap>
              {props.missingFeatures.map((feature) => (
                <Flag
                  key={feature}
                  label={feature}
                  severity="neutral"
                  data-testid={suffixTestId('missingFeature', props)}
                />
              ))}
            </HStack>
          </VStack>
        </Grid>
      </VStack>
    </VinDecoderCard>
  );
}
