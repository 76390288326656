import {Tooltip} from 'platform/components';
import {
  Box,
  getValueByDevice,
  Hide,
  HStack,
  Show,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';
import styled from 'styled-components';

import {Fragment} from 'react';
import {useParams} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';

import {EMPTY_PLACEHOLDER, suffixTestId, TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../components/AvoidBreakInside/AvoidBreakInside';
import {Flag, FlagSeverity} from '../../../../components/Flag/Flag';
import i18n from '../../../../i18n/i18n';
import {Equipment} from '../../types/Equipment';
import {parseIntIfNotNil} from '../../utils/parseIntIfNotNil';

const isOdd = (index: number) => index % 2 === 1;
const backgroundColor = (index: number) => (isOdd(index) ? 'accent.lightGrey' : undefined);

const truncateCode = (code?: string | null) => {
  if (isNil(code)) {
    return EMPTY_PLACEHOLDER;
  }

  if (code.length > 5) {
    return `${code.substring(0, 5)}.`;
  }

  return code;
};

interface EquipmentListProps extends TestIdProps {
  data: Equipment[];
  flagSeverity?: FlagSeverity;
  currency?: string;
  hasPriceHeadingShown?: boolean;
  isTranslationPending?: boolean;
}

export function EquipmentList(props: EquipmentListProps) {
  const formatCurrency = useFormatCurrency();
  const device = useDevice();
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';

  return (
    <Grid $columns={isPrintForced ? 2 : getValueByDevice(device, 1, 2)}>
      <Box paddingTop={2} paddingBottom={isPrintForced ? 2 : [1, 2]} paddingHorizontal={3}>
        <Text size="xSmall" color="secondary">
          {i18n.t('entity.vehicleEquipment.labels.code')}
        </Text>
      </Box>
      <Box
        paddingLeft={isPrintForced ? 0 : [3, 0]}
        paddingRight={3}
        paddingTop={isPrintForced ? 2 : [0, 2]}
        paddingBottom={2}
      >
        <HStack justify="space-between">
          <Text size="xSmall" color="secondary">
            {i18n.t('entity.vehicleEquipment.labels.itemName')}
          </Text>
          <Show when={props.hasPriceHeadingShown}>
            <Text size="xSmall" color="secondary">
              {i18n.t('entity.vehicleEquipment.labels.price')}
            </Text>
          </Show>
        </HStack>
      </Box>
      {props.data.map((equipment, index) => (
        <Fragment
          // Can be `index`, because data doesn't change during runtime
          // eslint-disable-next-line react/no-array-index-key
          key={index}
        >
          <Box
            paddingTop={2}
            paddingBottom={isPrintForced ? 2 : [1, 2]}
            paddingHorizontal={3}
            backgroundColor={backgroundColor(index)}
          >
            <HStack height="100%" align="center">
              <Show when={isNotNil(equipment?.manufacturerCode)}>
                <Tooltip label={equipment?.manufacturerCode} placement="top">
                  <Flag
                    label={truncateCode(equipment?.manufacturerCode)}
                    severity={props.flagSeverity}
                    data-testid={suffixTestId('equipmentCode', props)}
                  />
                </Tooltip>
              </Show>
            </HStack>
          </Box>
          <Box
            paddingLeft={isPrintForced ? 0 : [3, 0]}
            paddingRight={3}
            paddingTop={isPrintForced ? 2 : [0, 2]}
            paddingBottom={2}
            backgroundColor={backgroundColor(index)}
          >
            <AvoidBreakInside>
              <VStack height="100%" justify="center" spacing={1}>
                <HStack justify="space-between">
                  <Text size="small" overflowWrap="anywhere">
                    {(props.isTranslationPending ? null : equipment?.descriptionLocalLanguage) ??
                      equipment?.description}
                  </Text>
                  <Show when={isNotNil(equipment?.originalPrice) && isNotNil(props.currency)}>
                    <Text size="small" alternative>
                      {formatCurrency(parseIntIfNotNil(equipment?.originalPrice)!, props.currency!)}
                    </Text>
                  </Show>
                </HStack>
                <Hide when={props.isTranslationPending}>
                  <Text size="xSmall" color="secondary" overflowWrap="anywhere">
                    {equipment?.description}
                  </Text>
                </Hide>
              </VStack>
            </AvoidBreakInside>
          </Box>
        </Fragment>
      ))}
    </Grid>
  );
}

// Grid with first column the thinnest possible – platform Grid can't handle this
const Grid = styled.div<{$columns: 1 | 2}>`
  display: grid;
  grid-template-columns: ${(props) => (props.$columns === 1 ? undefined : 'auto 1fr')};
`;
