import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {catalogueBaseQuery} from './baseQuery/catalogueBaseQuery';
import {
  GetAllApiEnumsApiArg,
  GetAllApiEnumsApiResponse,
  GetApiMakeModelWithMakeApiArg,
  GetApiMakeModelWithMakeApiResponse,
} from './types/catalogueTypes';

export const catalogueApi = createApi({
  reducerPath: 'catalogue',
  baseQuery: catalogueBaseQuery,
  endpoints: (build) => ({
    getAllApiEnums: build.query<GetAllApiEnumsApiResponse, GetAllApiEnumsApiArg>({
      query: (queryArg) => ({
        url: '/api/enum',
        params: {
          lang: queryArg.lang,
          order_by: queryArg.orderBy,
          vehicle_type: queryArg.vehicleType,
        },
      }),
    }),
    getApiMakeModelWithMake: build.query<
      GetApiMakeModelWithMakeApiResponse,
      GetApiMakeModelWithMakeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/make-model/${queryArg.vehicleType}/${queryArg.make}`,
        params: {lang: queryArg.lang, logo_url: queryArg.logoUrl},
      }),
    }),
  }),
});

export const {useGetAllApiEnumsQuery, useGetApiMakeModelWithMakeQuery} = catalogueApi;
