import {Box, Heading, Image, Text, VStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {i18n} from '../../i18n/i18n';

export function ErrorPage(props: TestIdProps) {
  return (
    <Box padding={6}>
      <VStack spacing={6} align="center">
        <Image
          src="./assets/images/logo.svg"
          alt="VIN Decoder"
          height={12}
          width="auto"
          data-testid={suffixTestId('logo', props)}
        />
        <VStack align="center">
          <Heading size={2}>{i18n.t('page.error.labels.errorMessage')}</Heading>
          <Text size="small" color="tertiary">
            {i18n.t('page.error.labels.errorMessageDescription')}
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
}
