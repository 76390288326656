import {registerLocale} from 'i18n-iso-countries';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import {initReactI18next} from 'react-i18next';

import {queryParams} from '../consts/queryParams';
import {FALLBACK_LANGUAGE} from './fallbackLanguage';
import * as Locales from './locales/index';

registerLocale(require('i18n-iso-countries/langs/cs.json'));
registerLocale(require('i18n-iso-countries/langs/de.json'));
registerLocale(require('i18n-iso-countries/langs/en.json'));
registerLocale(require('i18n-iso-countries/langs/es.json'));
registerLocale(require('i18n-iso-countries/langs/fr.json'));
registerLocale(require('i18n-iso-countries/langs/it.json'));
registerLocale(require('i18n-iso-countries/langs/pl.json'));
registerLocale(require('i18n-iso-countries/langs/sk.json'));

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    detection: {
      lookupQuerystring: queryParams.LNG,
    },
    fallbackLng: FALLBACK_LANGUAGE,
    defaultNS: 'translation',
    resources: {
      cs: {
        translation: Locales.cs_CZ,
      },
      de: {
        translation: Locales.de_DE,
      },
      en: {
        translation: Locales.en_GB,
      },
      es: {
        translation: Locales.es_ES,
      },
      fr: {
        translation: Locales.fr_FR,
      },
      it: {
        translation: Locales.it_IT,
      },
      pl: {
        translation: Locales.pl_PL,
      },
      sk: {
        translation: Locales.sk_SK,
      },
    },
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      prefix: '{',
      suffix: '}',
    },
    react: {
      useSuspense: true,
    },
    pluralSeparator: '.',
  });

export default i18n;
export {i18n};
