import {StrictMode} from 'react';
import * as ReactDOM from 'react-dom/client';
import {BrowserRouter} from 'react-router-dom';

import {App} from './App';
import {MainBoundary} from './components/MainBoundary/MainBoundary';
import {NavigationContextProvider} from './pages/MainPage/components/NavigationContext/NavigationContext';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <MainBoundary>
        <NavigationContextProvider>
          <App />
        </NavigationContextProvider>
      </MainBoundary>
    </BrowserRouter>
  </StrictMode>
);
