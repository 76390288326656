import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import {isNil, isNotNil} from 'ramda';

import {useGetAllApiEnumsQuery, useGetApiMakeModelWithMakeQuery} from '../api/catalogueApi';
import {VehicleType} from '../api/types/catalogueTypes';
import {useV1NGetDecoderQuery} from '../api/vinDecoderApi';
import {FALLBACK_LANGUAGE} from '../i18n/fallbackLanguage';
import {i18n} from '../i18n/i18n';

export function useGetVinDecoderData() {
  const params = useParams<{decoderId: string}>();
  const lang = i18n?.resolvedLanguage ?? FALLBACK_LANGUAGE;
  const [pollingInterval, setPollingInterval] = useState(0);

  const {
    data: decoder,
    isLoading: isDecoderLoading,
    isError: isDecoderError,
    error: decoderError,
  } = useV1NGetDecoderQuery({id: params.decoderId ?? null}, {pollingInterval});
  const {
    data: catalogue,
    isLoading: isCatalogueLoading,
    isError: isCatalogueError,
  } = useGetAllApiEnumsQuery(
    {
      vehicleType: isNotNil(decoder?.data?.vehicleType)
        ? [decoder?.data?.vehicleType as VehicleType]
        : undefined,
      lang: [lang],
    },
    {skip: isNil(decoder?.data?.vehicleType)}
  );
  const {
    data: makeModel,
    isLoading: isMakeModelLoading,
    isError: isMakeModelError,
  } = useGetApiMakeModelWithMakeQuery(
    {
      vehicleType: (decoder?.data?.vehicleType as VehicleType) ?? undefined,
      lang: [lang],
      make: decoder?.data?.make ?? '',
      logoUrl: true,
    },
    {skip: isNil(decoder?.data?.vehicleType) || isNil(decoder?.data?.make)}
  );

  const isNotFound =
    isNotNil(decoderError) && 'status' in decoderError && decoderError.status === 404;
  const isExpired =
    isNotNil(decoderError) && 'status' in decoderError && decoderError.status === 410;

  const makeData = isNotNil(makeModel) ? makeModel[0] : undefined;
  const make = makeData?.default_label;
  const makeLogo = makeData?.black_logo_url_2x;
  const modelFamily = makeData?.models?.find(
    (model) => model.model === decoder?.data?.modelFamily
  )?.default_label;

  useEffect(() => {
    if (decoder?.translationStatus === 'PENDING') {
      setPollingInterval(10000);
    } else {
      setPollingInterval(0);
    }
  }, [decoder]);

  return {
    decoder,
    catalogue,
    make,
    makeLogo,
    modelFamily,
    isNotFound,
    isExpired,
    isLoading: isDecoderLoading || isCatalogueLoading || isMakeModelLoading,
    isError: isDecoderError || isCatalogueError || isMakeModelError,
  };
}
