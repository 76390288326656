import {ComponentsProvider} from 'platform/components';
import {StyleSheetManager} from 'styled-components';

import {ReactNode} from 'react';
import {HelmetProvider} from 'react-helmet-async';
import {Provider} from 'react-redux';

import {GlobalStyles} from '../../GlobalStyles';
import {i18n} from '../../i18n/i18n';
import {store} from '../../store/store';
import {theme} from '../../theme/theme';

interface MainBoundaryProps {
  children: ReactNode;
}

export function MainBoundary(props: MainBoundaryProps) {
  return (
    <Provider store={store}>
      <StyleSheetManager enableVendorPrefixes>
        <ComponentsProvider theme={theme} t={i18n.t} language={i18n.language}>
          <HelmetProvider>
            {props.children}
            <GlobalStyles />
          </HelmetProvider>
        </ComponentsProvider>
      </StyleSheetManager>
    </Provider>
  );
}
