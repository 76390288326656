import {fetchBaseQuery} from '@reduxjs/toolkit/query';
import {createApi} from '@reduxjs/toolkit/query/react';

import {environment} from '@price-report/environment';

export const omneticToolsApi = createApi({
  reducerPath: 'omneticTools',
  baseQuery: fetchBaseQuery({
    baseUrl: environment.OMNETIC_TOOLS_API_URL,
  }),
  endpoints: (build) => ({
    getDownloadLink: build.mutation<{url: string}, {content: string; filename: string}>({
      query: (body) => ({
        url: '/html-to-pdf',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {useGetDownloadLinkMutation} = omneticToolsApi;
