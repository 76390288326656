import {isNotNil} from 'ramda';
import {isNotNilOrEmpty} from 'ramda-adjunct';

export const formatValueWithUnit = (
  value?: string | number | null,
  unit?: string,
  hasSpace = true
) => {
  if (isNotNil(value)) {
    return `${value}${isNotNilOrEmpty(unit) ? `${hasSpace ? ' ' : ''}${unit}` : ''}`;
  }
  return undefined;
};
