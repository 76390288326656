import {useLayoutEffect} from 'react';
import {Outlet, useParams} from 'react-router-dom';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {useQueryState} from 'shared';

import {queryParams} from '../../consts/queryParams';
import {i18n} from '../../i18n/i18n';

export function ApplicationInitializeBoundary() {
  const params = useParams<{auditId: string}>();
  const [language] = useQueryState(queryParams.LNG);

  useLayoutEffect(() => {
    if (isSupportedLanguage(language) && language !== i18n?.resolvedLanguage) {
      i18n.changeLanguage(language!);
    }
  }, [language, params.auditId]);

  return <Outlet />;
}

const isSupportedLanguage = (lng: any) => isNotNilOrEmpty(lng);
