export const CHECK_FOR_APP_UPDATE_INTERVAL_MS = 300_000;

export const APPLICATION_NAME = 'OMNETIC';

export const moduleKeys = {
  core: 'core',
  vehicles: 'vehicles',
  sourcing: 'sourcing',
  quickPurchase: 'quickPurchase',
  customers: 'customers',
  businessCase: 'business-case',
  accounting: 'accounting',
  employee: 'employee',
  workshop: 'workshop',
  warehouse: 'warehouse',
  restrictedArea: 'restricted-area',
  inspection: 'inspection',
  settings: 'settings',
  analytics: 'analytics',
  tiresInventory: 'tires-inventory',
  interest: 'interest',
  taskManager: 'task-manager',
} as const;

export const browserStorageKey = {
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  BACKUP_URL: 'backupUrl',
  LAST_KNOWN_LANGUAGE: 'lastKnownLanguage',
  ALPHA_CATALOGUE_VERSION: 'alphaCatalogueVersion',
  CURRENT_VERSION: 'currentVersion',
  PERMISSIONS: 'userPermissions',
  CI_MODE: 'cimode',
  MOCK_FLAGS_KEY: 'featureFlagMock',
};

export const appWorkspaceKey = 'app';
export const redirectLink = 'redirectLink';
export const document360 = 'doc360';
