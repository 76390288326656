import {isNotNil} from 'ramda';

import {V1NGetDecoderApiResponse} from '../../../../../api/types/vinDecoderTypes';

type Images = NonNullable<NonNullable<V1NGetDecoderApiResponse>['data']>['images'];

export const filterOutNullishValues = (images: Images) =>
  images
    ?.filter(isNotNil)
    ?.filter((image) => isNotNil(image.id) && isNotNil(image.url))
    ?.map((image) => image as {id: string; url: string}) ?? [];
