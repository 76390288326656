import {Box, HStack, Icon, Text, ThemeColorPath, ThemeIconKey, VStack} from 'platform/foundation';
import {match} from 'ts-pattern';

import {ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

type AlertSeverity = 'accent' | 'informational' | 'warning';

export type AlertProps = TestIdProps & {
  children?: ReactNode;
  severity?: AlertSeverity;
} & ({icon: ThemeIconKey; iconColor: ThemeColorPath} | {iconComponent: ReactNode});

export function Alert(props: AlertProps) {
  // Icon for regular usage, iconComponent for special cases, i.e. when icon is multicolor
  const icon: ReactNode =
    ('icon' in props && <Icon value={props.icon} size={8} color={props.iconColor} />) ||
    ('iconComponent' in props && props.iconComponent) ||
    null;

  const lightColor = match<AlertSeverity | undefined, ThemeColorPath>(props.severity)
    .with('informational', () => 'severity.informationalLight')
    .with('warning', () => 'severity.warningLight')
    .otherwise(() => 'accent.light');

  const darkColor = match<AlertSeverity | undefined, ThemeColorPath>(props.severity)
    .with('informational', () => 'severity.informational')
    .with('warning', () => 'severity.warning')
    .otherwise(() => 'accent.tertiary');

  return (
    <Box backgroundColor={lightColor} data-testid={suffixTestId('alert', props)}>
      <HStack align="stretch">
        <Box padding={4} backgroundColor={darkColor}>
          <VStack height="100%" align="center" justify="center">
            {icon}
          </VStack>
        </Box>
        <Box padding={[3, 5]}>
          <VStack height="100%" justify="center">
            <Text>{props.children}</Text>
          </VStack>
        </Box>
      </HStack>
    </Box>
  );
}
