import {Box, HStack, Text, ThemeIconKey} from 'platform/foundation';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import i18n from '../../../../../i18n/i18n';
import {useNavigation} from '../../../components/NavigationContext/NavigationContext';
import {VinDecoderCard} from '../../../components/VinDecoderCard/VinDecoderCard';

interface VehicleInfoCardProps extends TestIdProps {
  heading: string;
  icon: ThemeIconKey;
  data: {label: string; value?: string | number | null}[];
}

export function VehicleInfoCard(props: VehicleInfoCardProps) {
  const {registerSection} = useNavigation();
  const ref = registerSection(props.heading);
  const records = props.data.filter((item) => isNotNil(item.value)).length;

  return (
    <VinDecoderCard
      icon={props.icon}
      heading={props.heading}
      summary={i18n.t('entity.vehicleInfo.labels.recordCount', {count: records})}
      ref={ref}
      data-testid={suffixTestId('vinDecoderCard', props)}
    >
      {props.data.map((item, index) => (
        <Box
          // Not guaranteed to be unique, so index had to be added
          // eslint-disable-next-line react/no-array-index-key
          key={`${item.label}-${index}`}
          paddingHorizontal={3}
          paddingVertical={2}
          backgroundColor={index % 2 === 1 ? 'accent.lightGrey' : undefined}
        >
          <HStack align="center" spacing={3}>
            <Box flex={1}>
              <Text size="small" alternative>
                {item.label}
              </Text>
            </Box>
            <Box flex={1}>
              <Text size="small">{item.value ?? '–'}</Text>
            </Box>
          </HStack>
        </Box>
      ))}
    </VinDecoderCard>
  );
}
