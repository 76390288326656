import {VStack} from 'platform/foundation';
import {useFormatNumber} from 'platform/locale';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {useCatalogue} from '../../../../hooks/useCatalogue';
import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import i18n from '../../../../i18n/i18n';
import {VehicleInfoCard} from './components/VehicleInfoCard';
import {useFloatNumberFormatter} from './hooks/useFloatNumberFormatter';
import {formatValueWithUnit} from './utils/formatValueWithUnit';

export function VehicleInfo(props: TestIdProps) {
  const {decoder, make, modelFamily} = useGetVinDecoderData();
  const formatNumber = useFormatNumber();
  const getLabel = useCatalogue();
  const parseAndFormatNumber = useFloatNumberFormatter();

  const engine =
    isNotNil(decoder?.data?.engineVolume) &&
    isNotNil(decoder?.data?.power) &&
    isNotNil(decoder?.data?.fuelType)
      ? i18n.t('entity.vehicleInfo.values.engine', {
          volume: formatNumber(parseInt(decoder?.data?.engineVolume ?? '0') / 1000),
          powerKW: decoder?.data?.power,
          fuel: getLabel('fuel_type', decoder?.data?.fuelType),
        })
      : undefined;

  const basicInfoData = [
    {
      label: i18n.t('entity.vehicleInfo.labels.vehicleType'),
      value: getLabel('vehicle_type', decoder?.data?.vehicleType),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.make'),
      value: make,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.modelFamily'),
      value: modelFamily,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.model'), // TODO T20-50689: missing
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.bodyStyle'),
      value: getLabel('car_style', decoder?.data?.bodyStyle),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.engine'),
      value: engine, // TODO T20-50689: incomplete
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.transmission'),
      value: getLabel('transmission', decoder?.data?.transmission),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.equipmentLevel'),
      value: decoder?.data?.equipmentLevel,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.doorCount'),
      value: decoder?.data?.doorCount, // TODO T20-50689: bodyType changed to doorCount
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.codeKBA'),
      value: decoder?.data?.codeKBA,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.productGroup'),
      value: decoder?.data?.productGroup,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.vin'),
      value: decoder?.data?.vin,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.licensePlateNumber'),
      value: decoder?.data?.licensePlateNumber,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.mileage'),
      value: decoder?.data?.milage,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.mileageLastEntry'),
      value: decoder?.data?.milageLastEntry,
    },
  ];

  const vehicleDetailsData = [
    {
      label: i18n.t('entity.vehicleInfo.labels.doorCount'),
      value: decoder?.data?.doorCount,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.seatCount'),
      value: decoder?.data?.seatCount,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.countOfAirbags'),
      value: decoder?.data?.countOfAirbags,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.originalTireSizeFront'),
      value: decoder?.data?.originalTireSizeFront,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.originalTireSizeRear'),
      value: decoder?.data?.originalTireSizeRear,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.rentalCarClass'),
      value: decoder?.data?.rentalCarClass,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.classPP'),
      value: decoder?.data?.classPP,
    },
  ];

  const engineAndDriveData = [
    {
      label: i18n.t('entity.vehicleInfo.labels.engineVolume'),
      value: formatValueWithUnit(decoder?.data?.engineVolume, i18n.t('general.unit.ccm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.countOfCylinders'),
      value: decoder?.data?.countOfCylinders,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.cylindersArrangement'),
      value: decoder?.data?.cylindersArrangement,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.powerKW'),
      value: formatValueWithUnit(decoder?.data?.power, i18n.t('general.unit.kW')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.powerHP'),
      value: formatValueWithUnit(decoder?.data?.powerHP, i18n.t('general.unit.HP')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.primaryPowerKW'),
      value: formatValueWithUnit(decoder?.data?.primaryPowerKW, i18n.t('general.unit.kW')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.primaryPowerHP'),
      value: formatValueWithUnit(decoder?.data?.primaryPowerHP, i18n.t('general.unit.HP')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.rotationsOnMaxPower'),
      value: formatValueWithUnit(
        decoder?.data?.rotationsOnMaxPower,
        i18n.t('general.unit.perMinute'),
        false
      ),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.torque'),
      value: formatValueWithUnit(decoder?.data?.torque, i18n.t('general.unit.Nm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.rotationsOnMaxTorque'),
      value: formatValueWithUnit(
        decoder?.data?.rotationsOnMaxTorque,
        i18n.t('general.unit.perMinute'),
        false
      ),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.engineCycle'),
      value: formatValueWithUnit(decoder?.data?.engineCycle, i18n.t('general.unit.stroke')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.powerKwPermanentSecondary'),
      value: formatValueWithUnit(
        decoder?.data?.powerKwPermanentSecondary,
        i18n.t('general.unit.kW')
      ),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.powerHpPermanentSecondary'),
      value: formatValueWithUnit(
        decoder?.data?.powerHpPermanentSecondary,
        i18n.t('general.unit.HP')
      ),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.powerHpMaxSecondary'), // TODO T20-50689: missing
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.powerKwMaxSecondary'), // TODO T20-50689: missing
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.batteryVoltage'),
      value: formatValueWithUnit(decoder?.data?.batteryVoltage, i18n.t('general.unit.V')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.batteryCapacity'),
      value: formatValueWithUnit(decoder?.data?.batteryCapacity, i18n.t('general.unit.kWh')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.batteryConstructionType'),
      value: decoder?.data?.batteryConstructionType,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.rangeOfElectricMotor'),
      value: formatValueWithUnit(decoder?.data?.rangeOfElectricMotor, i18n.t('general.unit.km')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.rangeTotal'),
      value: formatValueWithUnit(decoder?.data?.rangeTotal, i18n.t('general.unit.km')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.rangeElectricalMinWLTP'),
      value: formatValueWithUnit(decoder?.data?.rangeElectricalMinWLTP, i18n.t('general.unit.km')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.rangeElectricalMaxWLTP'),
      value: formatValueWithUnit(decoder?.data?.rangeElectricalMaxWLTP, i18n.t('general.unit.km')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.speedMax'),
      value: formatValueWithUnit(decoder?.data?.speedMax, i18n.t('general.unit.kmph')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.acceleration'),
      value: formatValueWithUnit(decoder?.data?.acceleration, i18n.t('general.unit.s')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.drive'),
      value: getLabel('drive', decoder?.data?.drive),
    },
  ];

  const consumptionAndCo2Data = [
    {
      label: i18n.t('entity.vehicleInfo.labels.fuelKind'),
      value: getLabel('fuel_type', decoder?.data?.fuelType), // TODO T20-50689: same?
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.fuelType'),
      value: getLabel('fuel_type', decoder?.data?.fuelType),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.fuelConsumptionUrban'),
      value: parseAndFormatNumber(decoder?.data?.fuelConsumptionUrban),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.fuelConsumptionExtraUrban'),
      value: parseAndFormatNumber(decoder?.data?.fuelConsumptionExtraUrban),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.fuelConsumptionCombined'),
      value: parseAndFormatNumber(decoder?.data?.fuelConsumptionCombined),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.carbonDioxideEmission'),
      value: formatValueWithUnit(decoder?.data?.carbonDioxideEmission, i18n.t('general.unit.gpkm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wltpConsumptionLowMin'),
      value: parseAndFormatNumber(decoder?.data?.wltpConsumptionLowMin),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wltpConsumptionLowMax'),
      value: parseAndFormatNumber(decoder?.data?.wltpConsumptionLowMax),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wltpConsumptionMediumMin'),
      value: parseAndFormatNumber(decoder?.data?.wltpConsumptionMediumMin),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wltpConsumptionMediumMax'),
      value: parseAndFormatNumber(decoder?.data?.wltpConsumptionMediumMax),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wltpConsumptionHighMin'),
      value: parseAndFormatNumber(decoder?.data?.wltpConsumptionHighMin),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wltpConsumptionHighMax'),
      value: parseAndFormatNumber(decoder?.data?.wltpConsumptionHighMax),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wltpCarbonDioxideEmissionMin'), // TODO T20-50689: missing
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wltpCarbonDioxideEmissionMax'), // TODO T20-50689: missing
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.emissionClass'),
      value: getLabel('emission_class', decoder?.data?.emissionClass), // TODO T20-50689: not exactly
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.dustLabel'),
      value: decoder?.data?.dustLabel,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.performanceClass'),
      value: decoder?.data?.performanceClass,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.tankVolume'),
      value: formatValueWithUnit(decoder?.data?.tankVolume, i18n.t('general.unit.l')),
    },
  ];

  const dimensionsAndWeightData = [
    {
      label: i18n.t('entity.vehicleInfo.labels.weight'),
      value: formatValueWithUnit(decoder?.data?.weight, i18n.t('general.unit.kg')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.countOfAxles'),
      value: decoder?.data?.countOfAxles,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.countOfDrivenAxles'),
      value: decoder?.data?.countOfDrivenAxles,
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.wheelBase'),
      value: formatValueWithUnit(decoder?.data?.dimensions?.wheelBase, i18n.t('general.unit.mm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.operatingWeight'),
      value: formatValueWithUnit(
        decoder?.data?.dimensions?.operatingWeight,
        i18n.t('general.unit.kg')
      ),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.maximalLoadCapacity'),
      value: formatValueWithUnit(
        decoder?.data?.dimensions?.maximalLoadCapacity,
        i18n.t('general.unit.kg')
      ),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.weightTotalAllowed'), // TODO T20-50689: missing
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.roofLoad'),
      value: formatValueWithUnit(decoder?.data?.roofLoad, i18n.t('general.unit.kg')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.trailerLoadBraked'),
      value: formatValueWithUnit(decoder?.data?.trailerLoadBraked, i18n.t('general.unit.kg')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.trailerLoadUnbraked'),
      value: formatValueWithUnit(decoder?.data?.trailerLoadUnbraked, i18n.t('general.unit.kg')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.weightTotalCombination'),
      value: formatValueWithUnit(decoder?.data?.weightTotalCombination, i18n.t('general.unit.kg')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.length'),
      value: formatValueWithUnit(decoder?.data?.dimensions?.length, i18n.t('general.unit.mm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.width'),
      value: formatValueWithUnit(decoder?.data?.dimensions?.width, i18n.t('general.unit.mm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.widthForGarage'),
      value: formatValueWithUnit(decoder?.data?.widthForGarage, i18n.t('general.unit.mm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.height'),
      value: formatValueWithUnit(decoder?.data?.dimensions?.height, i18n.t('general.unit.mm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.loadingLength'),
      value: formatValueWithUnit(decoder?.data?.loadingLength, i18n.t('general.unit.mm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.loadingWidth'),
      value: formatValueWithUnit(decoder?.data?.loadingWidth, i18n.t('general.unit.mm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.loadingHeight'),
      value: formatValueWithUnit(decoder?.data?.loadingHeight, i18n.t('general.unit.mm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.loadingSpace'),
      value: formatValueWithUnit(decoder?.data?.loadingSpace, i18n.t('general.unit.qdm')),
    },
    {
      label: i18n.t('entity.vehicleInfo.labels.loadingSpaceMax'),
      value: formatValueWithUnit(decoder?.data?.loadingSpaceMax, i18n.t('general.unit.qdm')),
    },
  ];

  return (
    <VStack spacing={6}>
      <VehicleInfoCard
        heading={i18n.t('entity.vehicleInfo.labels.basicInfo')}
        icon="assigment"
        data={basicInfoData}
        data-testid={suffixTestId('basicInfo-vehicleInfoCard', props)}
      />
      <VehicleInfoCard
        heading={i18n.t('entity.vehicleInfo.labels.vehicleDetails')}
        icon="assignmentNew"
        data={vehicleDetailsData}
        data-testid={suffixTestId('vehicleDetails-vehicleInfoCard', props)}
      />
      <VehicleInfoCard
        heading={i18n.t('entity.vehicleInfo.labels.engineAndDrive')}
        icon="engine"
        data={engineAndDriveData}
        data-testid={suffixTestId('engineAndDrive-vehicleInfoCard', props)}
      />
      <VehicleInfoCard
        heading={i18n.t('entity.vehicleInfo.labels.consumptionAndCo2')}
        icon="eco"
        data={consumptionAndCo2Data}
        data-testid={suffixTestId('consumptionAndCo2-vehicleInfoCard', props)}
      />
      <VehicleInfoCard
        heading={i18n.t('entity.vehicleInfo.labels.dimensionsAndWeight')}
        icon="dimensions"
        data={dimensionsAndWeightData}
        data-testid={suffixTestId('dimensionsAndWeight-vehicleInfoCard', props)}
      />
    </VStack>
  );
}
