import {
  Box,
  HStack,
  Icon,
  Show,
  Text,
  ThemeColorPath,
  ThemeColorTextPath,
  ThemeIconKey,
} from 'platform/foundation';
import {match} from 'ts-pattern';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {RequiredTestIdProps, suffixTestId} from 'shared';

export type FlagSeverity = 'default' | 'neutral' | 'success' | 'error' | 'info';

export interface FlagProps extends RequiredTestIdProps {
  label?: string;
  icon?: ThemeIconKey;
  severity?: FlagSeverity;
}

export function Flag(props: FlagProps) {
  const hasText = isNotNilOrEmpty(props.label);
  const textColor = match<FlagSeverity | undefined, ThemeColorTextPath>(props.severity)
    .with('info', () => 'darkBlue')
    .otherwise(() => 'white');
  const backgroundColor = match<FlagSeverity | undefined, ThemeColorPath>(props.severity)
    .with('neutral', () => 'accent.grey')
    .with('success', () => 'severity.success')
    .with('error', () => 'severity.error')
    .with('info', () => 'general.lightBlue')
    .otherwise(() => 'accent.primary');

  return (
    <Box
      padding={1}
      borderRadius="xSmall"
      backgroundColor={backgroundColor}
      data-testid={suffixTestId('flag', props)}
    >
      <HStack align="center">
        <Icon value={props.icon} size={4} color="text.white" />
        <Show when={hasText}>
          <Text size="small" color={textColor} alternative>
            {props.label}
          </Text>
        </Show>
      </HStack>
    </Box>
  );
}
