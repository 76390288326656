import {Center, HStack, Icon, LinkProps, Show, Text} from 'platform/foundation';
import styled from 'styled-components';

import {isNotNil} from 'ramda-adjunct';

import {suffixTestId} from 'shared';

export function ButtonLink(props: LinkProps) {
  return (
    <StyledLink
      rel={props.rel}
      target={props.target}
      download={props.download}
      href={props.isDisabled ? undefined : (props.href ?? undefined)}
      onClick={props.isDisabled ? undefined : props.onClick}
      aria-disabled={props.isDisabled}
      $isDisabled={props.isDisabled}
      $size={props.size ?? 'base'}
      data-testid={suffixTestId('buttonLink', props)}
    >
      <HStack spacing={2} align="center">
        <Show when={isNotNil(props.leftIcon)}>
          <Icon size={5} color="general.white" value={props.leftIcon} />
        </Show>
        <Center minHeight={5}>
          <Text color="white" size="small" data-testid={suffixTestId('buttonLinkContent', props)}>
            {props.title}
          </Text>
        </Center>
        <Show when={isNotNil(props.rightIcon)}>
          <Icon size={5} color="general.white" value={props.rightIcon} />
        </Show>
      </HStack>
    </StyledLink>
  );
}

const StyledLink = styled.a<{$isDisabled?: boolean; $size: 'base' | 'small' | 'xSmall'}>`
  display: inline-block;
  color: ${({theme}) => theme.colors.text.white};
  cursor: ${(props) => (props.$isDisabled ? 'auto' : 'pointer')};
  opacity: ${(props) => (props.$isDisabled ? 0.5 : undefined)};
  padding: ${(props) =>
    `${props.theme.getSize(props.$size === 'base' ? 3 : 2)} ${props.theme.getSize(props.$size === 'base' ? 6 : 4)}`};
  border-radius: ${({theme}) => theme.radii.small};
  background-color: ${({theme}) => theme.colors.accent.complementary};

  &:hover {
    text-decoration: none;
    background-color: ${({theme}) => theme.colors.accent.darkGrey};
  }
`;
