import {showNotification} from 'platform/components';

import {useParams} from 'react-router-dom';

import {useGetDownloadLinkMutation} from '../api/omneticToolsApi';
import {i18n} from '../i18n/i18n';
import {useGetVinDecoderData} from './useGetVinDecoderData';

const VIN_DECODER_DEV_URL = 'https://vin-decoder.dev.carsdata.dev';

export const useDownloadPDF = () => {
  const {make, modelFamily} = useGetVinDecoderData();
  const {decoderId} = useParams<{decoderId: string}>();
  const [downloadLink, {isLoading: isPreparingDownload}] = useGetDownloadLinkMutation();

  const vinDecoderName = `VinDecoder ${make} ${modelFamily}`;

  const handleDownload = () => {
    const isLocalhost = window.location.host.includes('localhost');
    const serverUrl = isLocalhost
      ? VIN_DECODER_DEV_URL
      : window.location.protocol + '//' + window.location.host;
    const currentLanguage = i18n.language;
    const pageToDownload = `${serverUrl}/${decoderId}/pdf?lang=${currentLanguage}`;
    const filename = toASCII(vinDecoderName);

    downloadLink({content: pageToDownload, filename})
      .unwrap()
      .then(({url}) => {
        window.location.assign(url);
      })
      .catch(() => {
        showNotification.error(i18n.t('general.notifications.downloadError'));
      });
  };

  return {
    handleDownload,
    isPreparingDownload,
  };
};

const toASCII = (str: string) =>
  str
    .normalize('NFD') // Normalize the string to decomposed form (NFD): é => e + ´
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritical marks
    .replace(/[^a-zA-Z0-9]/g, '_'); // Replace non-alphanumeric characters with underscores
