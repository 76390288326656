import {Heading, Text} from 'platform/foundation';

/**
 * Transforms a string input into an array of Heading and Text elements
 *
 * Headline is recognized by the following patterns:
 * - Old style: "Heading:" (starts with a capital letter and ends with a colon)
 * - New style: "**Heading**" (starts and ends with two asterisks)
 *
 * @example
 * // Example of formatting a simple multi-line string into paragraphs.
 * const sampleText = `Heading:\nFirst line.\nSecond line.\nThird line.`;
 * const paragraphs = formatToParagraphs(sampleText);
 *
 * // paragraphs would be an array of one <Heading> and three <Text> elements:
 * [
 *  <Heading key="Heading">Heading:</Heading>,
 *  <Text key="First line.">First line.</Text>,
 *  <Text key="Second line.">Second line.</Text>,
 *  <Text key="Third line.">Third line.</Text>
 * ]
 */
export const formatToParagraphs = (description?: string | null) =>
  description
    ?.split('\n')
    .filter((line) => line.length > 0)
    .map((line) => {
      const isOldStyleHeadline = /^[A-Z\u00C0-\u00DC].*:$/.test(line);
      const isNewStyleHeadline = /^\*\*.*\*\*$/.test(line);

      return isOldStyleHeadline || isNewStyleHeadline ? (
        <Heading key={line} size={4}>
          {isNewStyleHeadline ? line.substring(2, line.length - 2) : line}
        </Heading>
      ) : (
        <Text key={line}>{line}</Text>
      );
    });
