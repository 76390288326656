import {AdaptiveImage, Clickable, Hide, Image, Show} from 'platform/foundation';
import styled from 'styled-components';

import {useParams} from 'react-router-dom';

import {suffixTestId, TestIdProps} from 'shared';

import {makeStorageUrl} from '../../../../../utils/makeStorageUrl';
import {RATIO} from '../constants/ratio';

const A4_WIDTH_PX = 2480; // A4 width in pixels using 300 DPI
const maximumPrintContentWidth = A4_WIDTH_PX * 0.87; // Deduct ~13% padding

interface GalleryImageProps extends TestIdProps {
  index: number;
  url: string;
  shouldShowScreenImage: boolean;
  shouldLoadPrintImage: boolean;
  onClick: () => void;
  onLoad: () => void;
}

export function GalleryImage(props: GalleryImageProps) {
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';

  // Images for print has to be preloaded, otherwise they are not displayed
  const imagePrintWidth =
    props.index === 0
      ? Math.round(maximumPrintContentWidth)
      : Math.round(maximumPrintContentWidth / 2);
  const imagePrintHeight =
    props.index === 0
      ? Math.round(maximumPrintContentWidth / RATIO)
      : Math.round(maximumPrintContentWidth / 2 / RATIO);

  return (
    <>
      <Show when={props.shouldLoadPrintImage}>
        <PrintOnly $forcePrint={isPrintForced}>
          <Image
            src={makeStorageUrl(props.url, imagePrintWidth, imagePrintHeight, 'cover')}
            data-testid={suffixTestId('imagePrint', props)}
          />
        </PrintOnly>
      </Show>
      <Hide inPrinter when={!props.shouldShowScreenImage || isPrintForced}>
        <Clickable onClick={props.onClick}>
          <AdaptiveImage
            url={props.url}
            makeUrl={makeStorageUrl}
            fit="cover"
            ratio={RATIO.toString()}
            onLoad={props.onLoad}
            data-testid={suffixTestId('imageScreen', props)}
          />
        </Clickable>
      </Hide>
    </>
  );
}

const PrintOnly = styled.div<{$forcePrint: boolean}>`
  display: ${(props) => (props.$forcePrint ? 'block' : 'none')};

  @media print {
    display: block;
  }
`;
