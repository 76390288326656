import {isNotNil} from 'ramda';

import {useGetVinDecoderData} from './useGetVinDecoderData';

export const useCatalogue = () => {
  const {catalogue} = useGetVinDecoderData();

  return (name?: string, value?: string | null, fallback: 'key' | 'null' = 'key') => {
    if (isNotNil(catalogue) && isNotNil(name) && isNotNil(value)) {
      const propertyEnum = catalogue.find((item) => item.property_name === name)?.keys;
      const labels = propertyEnum?.find((item) => item.const_key === value)?.labels;
      if (isNotNil(labels) && labels.length > 0) {
        return labels[0].label;
      }
    }
    return fallback === 'key' ? value : null;
  };
};
