import {Text, VStack} from 'platform/foundation';

import {useParams} from 'react-router-dom';

import {isNotNil, sort} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {Alert} from '../../../../components/Alert/Alert';
import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import i18n from '../../../../i18n/i18n';
import {EquipmentList} from '../../components/EquipmentList/EquipmentList';
import {useNavigation} from '../../components/NavigationContext/NavigationContext';
import {VinDecoderCard} from '../../components/VinDecoderCard/VinDecoderCard';
import {sortNullishDescriptionEquipment} from '../../utils/sortNullishDescriptionEquipment';

const heading = i18n.t('entity.vehicleEquipment.labels.seriesEquipment');

export function SeriesVehicleEquipment(props: TestIdProps) {
  const {decoder} = useGetVinDecoderData();
  const {registerSection} = useNavigation();
  const ref = registerSection(heading);
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';
  const isTranslationPending = decoder?.translationStatus === 'PENDING';

  const seriesEquipment = sort(
    sortNullishDescriptionEquipment,
    (decoder?.data?.seriesEquipment ?? [])
      .concat(decoder?.data?.otherEquipment ?? [])
      .filter(isNotNil)
  );

  return (
    <VinDecoderCard
      icon="ballot"
      heading={heading}
      summary={i18n.t('entity.vehicleInfo.labels.recordCount', {count: seriesEquipment.length})}
      ref={ref}
      data-testid={suffixTestId('vinDecoderCard', props)}
    >
      <VStack spacing={isPrintForced ? 8 : [6, 8]}>
        <Alert
          severity={isTranslationPending ? 'warning' : 'informational'}
          icon="gpt"
          iconColor="palettes.neutral.900.100"
          data-testid={suffixTestId('translatedFeaturesAlert', props)}
        >
          {isTranslationPending
            ? `${i18n.t('entity.translationStatus.labels.loadingTitle')} ${i18n.t('entity.translationStatus.labels.loadingDescription')}`
            : i18n.t('entity.vehicleEquipment.labels.alertSomeFeaturesTranslated')}
        </Alert>
        <VStack spacing={6}>
          <Text>{i18n.t('entity.vehicleEquipment.labels.seriesEquipmentDescription')}</Text>
          <EquipmentList
            data={seriesEquipment}
            flagSeverity="info"
            isTranslationPending={isTranslationPending}
            data-testid={suffixTestId('seriesEquipmentList', props)}
          />
        </VStack>
      </VStack>
    </VinDecoderCard>
  );
}
