import Assignment from '../../assets/icons/assignment.svg';
import AssignmentNew from '../../assets/icons/assignment_new.svg';
import Ballot from '../../assets/icons/ballot.svg';
import Basket from '../../assets/icons/basket.svg';
import Calendar from '../../assets/icons/calendar.svg';
import CalendarAlt from '../../assets/icons/calendar_alt.svg';
import CarbonDioxideEmission from '../../assets/icons/carbon_dioxide_emission.svg';
import Comparison from '../../assets/icons/comparison.svg';
import Dimensions from '../../assets/icons/dimensions.svg';
import Drive from '../../assets/icons/drive.svg';
import Eco from '../../assets/icons/eco.svg';
import EmissionClass from '../../assets/icons/emission_class.svg';
import Engine from '../../assets/icons/engine.svg';
import FuelType from '../../assets/icons/fuel_type.svg';
import Gpt from '../../assets/icons/gpt.svg';
import Label from '../../assets/icons/label.svg';
import List from '../../assets/icons/list.svg';
import PhotoGallery from '../../assets/icons/photo_gallery.svg';
import PieChart from '../../assets/icons/pie_chart.svg';
import Power from '../../assets/icons/power.svg';
import Sell from '../../assets/icons/sell.svg';
import ShadowAdd from '../../assets/icons/shadow_add.svg';
import Transmission from '../../assets/icons/transmission.svg';
import Trend from '../../assets/icons/trend.svg';
import Trophy from '../../assets/icons/trophy.svg';
import VehicleType from '../../assets/icons/vehicle_type.svg';
import Wallet from '../../assets/icons/wallet.svg';
import Year from '../../assets/icons/year.svg';

export const icons = {
  assigment: Assignment,
  assignmentNew: AssignmentNew,
  ballot: Ballot,
  basket: Basket,
  calendar: Calendar,
  calendarAlt: CalendarAlt,
  carbonDioxideEmission: CarbonDioxideEmission,
  comparison: Comparison,
  dimensions: Dimensions,
  drive: Drive,
  eco: Eco,
  emissionClass: EmissionClass,
  engine: Engine,
  fuelType: FuelType,
  gpt: Gpt,
  label: Label,
  list: List,
  photoGallery: PhotoGallery,
  pieChart: PieChart,
  power: Power,
  sell: Sell,
  shadowAdd: ShadowAdd,
  transmission: Transmission,
  trend: Trend,
  trophy: Trophy,
  vehicleType: VehicleType,
  wallet: Wallet,
  year: Year,
} as const;
