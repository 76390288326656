import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {useCatalogue} from '../../../../hooks/useCatalogue';
import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import {FeaturesCard} from './components/FeaturesCard';

export function Features(props: TestIdProps) {
  const {decoder} = useGetVinDecoderData();
  const featureLevel = decoder?.data?.featuresScore ?? 0;
  const avgFeatureLevel = 0;
  const getLabel = useCatalogue();
  const mapFeatures = (features?: (string | null)[]) =>
    (features
      ?.map((feature) => getLabel('feature', feature, 'null'))
      ?.filter((feature) => isNotNil(feature)) as string[]) ?? [];
  const availableFeatures = mapFeatures(decoder?.data?.featuresRecommended?.available);
  const missingFeatures = mapFeatures(decoder?.data?.featuresRecommended?.missing);

  return (
    <FeaturesCard
      featureLevel={featureLevel}
      featureLevelDiff={featureLevel - avgFeatureLevel}
      availableFeatures={availableFeatures}
      missingFeatures={missingFeatures}
      data-testid={suffixTestId('featuresCard', props)}
    />
  );
}
