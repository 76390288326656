import {SpinnerConfig, themeIcons} from 'platform/foundation';
import {DeepPartial} from 'utility-types';

import {icons} from '../assets/icons/icons';

const radii = {
  xSmall: '4px',
  small: '8px',
  medium: '16px',
  large: '24px',
  border_radius_full: '1000px',
};

const fonts = {
  body: 'Inter, sans-serif',
  heading: 'Inter, sans-serif',
} as const;

const fontSizes = {
  display: {
    printer: {1: '2.5rem'},
    desktop: {1: '3.5rem'},
    notebook: {1: '3.5rem'},
    tablet: {1: '2.5rem'},
    mobile: {1: '1.75rem'},
  },
  heading: {
    printer: {
      1: '2rem',
      2: '1.25rem',
      3: '1.125rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
    desktop: {
      1: '2.5rem',
      2: '1.5rem',
      3: '1.3125rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
    notebook: {
      1: '2.5rem',
      2: '1.5rem',
      3: '1.3125rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
    tablet: {
      1: '2rem',
      2: '1.25rem',
      3: '1.125rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
    mobile: {
      1: '1.75rem',
      2: '1.25rem',
      3: '1rem',
      4: '1rem',
      5: '0.875rem',
      6: '0.75rem',
    },
  },
  text: {
    large: '1.25rem',
    base: '1rem',
    small: '0.875rem',
    xSmall: '0.75rem',
    xxSmall: '0.625rem',
  },
} as const;

const lineHeights = {
  display: {
    printer: {
      1: 1.3,
    },
    desktop: {
      1: 1.3,
    },
    notebook: {
      1: 1.3,
    },
    tablet: {
      1: 1.2,
    },
    mobile: {
      1: 1.2,
    },
  },
  heading: {
    printer: {
      1: 1.4,
      2: 1.4,
      3: 1.4,
      4: 1.5,
      5: 1.4,
      6: 1.4,
    },
    desktop: {
      1: 1.4,
      2: 1.4,
      3: 1.4,
      4: 1.5,
      5: 1.4,
      6: 1.4,
    },
    notebook: {
      1: 1.4,
      2: 1.4,
      3: 1.4,
      4: 1.5,
      5: 1.4,
      6: 1.4,
    },
    tablet: {
      1: 1.4,
      2: 1.4,
      3: 1.4,
      4: 1.5,
      5: 1.4,
      6: 1.4,
    },
    mobile: {
      1: 1.4,
      2: 1.4,
      3: 1.5,
      4: 1.5,
      5: 1.4,
      6: 1.4,
    },
  },
  text: {
    large: 1.4,
    base: 1.5,
    small: 1.4,
    xSmall: 1.4,
    xxSmall: 1.4,
  },
} as const;

const fontWeights = {
  regular: 400,
  medium: 500,
  bold: 600,
  display: {
    default: 700,
    alternative: 400,
  },
  heading: {
    default: 700,
    alternative: 400,
  },
  text: {
    default: 400,
    alternative: 700,
  },
} as const;

const general = {
  accent: '#FF9922',
  stroke: '#EAEAEA',
  lightBlue: '#DCEFFF',
  overlay: '#00000099',
} as const;

const accent = {
  primary: '#FF9922',
  secondary: '#F08200',
  tertiary: '#FEE5C8',
  light: '#FFF5E9',
  dark: '#A05600',
  complementary: '#141416',
  grey: '#AAAAAA',
  lightGrey: '#F6F6F6',
  darkGrey: '#323237',
} as const;

const text = {
  primary: '#1F1F1F',
  secondary: '#747577',
  accent: '#FF9922',
  white: '#FFFFFF',
  darkBlue: '#0747A6',
} as const;

const severity = {
  informationalLight: '#EFF5FF',
  informational: '#D1E2FF',
  warningLight: '#FFEB98',
  warning: '#FBCE19',
  success: '#4FA931',
  error: '#D00025',
} as const;

const border = {
  emphasisDefault: '#000000',
} as const;

const breakpoints = {
  notebook: 1370,
  tablet: 1024,
  mobile: 768,
} as const;

const devices = {
  desktop: `(min-width: ${breakpoints.notebook}px)`,
  notebook: `(min-width: ${breakpoints.tablet}px) and (max-width: ${breakpoints.notebook}px)`,
  tablet: `(min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.tablet}px)`,
  mobile: `(max-width: ${breakpoints.mobile}px)`,
  printer: 'print',
} as const;

const zIndices = {
  PREVIEW: 1,
  MENU_BAR: 1000,
  NAVIGATION_TABS: 7000,
  CHOICE_MENU: 8000,
  MOBILE_NAVIGATION: 9000,
  LANGUAGE_DIALOG: 9000,
  LIGHTBOX_BODY: 10000,
  LIGHTBOX_SLIDER_ARROW: 10001,
  LIGHTBOX_OVERFLOW_OVERLAY: 10002,
  LIGHTBOX_OVERFLOW_MENU: 10003,
} as const;

const spinnerConfig: DeepPartial<SpinnerConfig> = {
  color: {
    light: 'palettes.black.10.100',
  },
};

export const theme = {
  radii,
  fonts,
  fontWeights,
  fontSizes,
  lineHeights,
  devices,
  zIndices,
  colors: {
    general,
    accent,
    text,
    severity,
    border,
  },
  icons: {
    ...icons,
    ...themeIcons,
  },
  components: {
    Spinner: spinnerConfig,
  },
  styles: {
    global: {
      html: {
        overflowX: 'unset',
      },
    },
  },
} as const;
