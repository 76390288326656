import {motion, usePresence} from 'framer-motion';
import styled from 'styled-components';
import {match} from 'ts-pattern';

import {forwardRef, ReactNode, Ref, useEffect} from 'react';

import {always} from 'ramda';

import {DialogSize} from '../types/DialogSize';
import {ScrollBehavior} from '../types/ScrollBehavior';
import {dialogTransitions} from '../utils/dialogTransitions';

export interface ModalTransitionProps {
  size?: DialogSize;
  preset?: 'slideInBottom' | 'slideInRight' | 'scale' | 'none';
  children: ReactNode;
  scrollBehavior?: ScrollBehavior;
}

export const ModalTransition = forwardRef((props: ModalTransitionProps, ref: Ref<any>) => {
  const motionProps = dialogTransitions[props.preset ?? 'none'];

  const [isPresent, safeToRemove] = usePresence();

  useEffect(() => {
    if (!isPresent && safeToRemove) {
      setTimeout(safeToRemove);
    }
  }, [isPresent, safeToRemove]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
  };

  return (
    <StyledSection
      onClick={handleClick}
      $scrollBehavior={props.scrollBehavior}
      $size={props.size}
      ref={ref}
      {...motionProps}
    >
      {props.children}
    </StyledSection>
  );
});

type StyledSectionProp = {
  $size: ModalTransitionProps['size'];
  $scrollBehavior: ModalTransitionProps['scrollBehavior'];
};

const StyledSection = styled(motion.section)<StyledSectionProp>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  outline: 0;
  background-color: ${({theme}) => theme.colors.palettes.white[10][100]};
  box-shadow: ${({theme}) => theme.shadows.elevation_4};
  border-width: 1px;
  border-radius: ${({theme}) => theme.radii.medium};
  border-color: ${({theme}) => theme.colors.palettes.neutral[40][100]};
  margin: 0 3.75rem;
  max-height: ${({$scrollBehavior}) =>
    $scrollBehavior === 'inside' ? 'calc(100% - 7.5rem)' : 'calc(100% - 2rem)'};
  max-width: ${({$size}) =>
    match<DialogSize | undefined>($size)
      .with('small', always('400px'))
      .with('large', always('1200px'))
      .otherwise(always('800px'))};
`;
