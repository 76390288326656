import {Box, Heading, HStack, Stack, Text, VStack} from 'platform/foundation';

import {ForwardedRef, forwardRef, ReactNode} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {ButtonLink} from '../../../../../components/ButtonLink/ButtonLink';
import {Flag} from '../../../../../components/Flag/Flag';
import i18n from '../../../../../i18n/i18n';

interface BannerProps extends TestIdProps {
  children: ReactNode;
  logo: ReactNode;
  heading: string;
  description: string;
  actualPrice: string;
  originalPrice: string;
  discount: string;
  purchaseLink: string;
}

export const Banner = forwardRef((props: BannerProps, ref: ForwardedRef<HTMLDivElement>) => (
  <Box borderRadius="small" overflow="hidden" ref={ref}>
    <Stack direction={['column', 'column', 'row']}>
      <Box backgroundColor="accent.lightGrey" flex={3}>
        {props.children}
      </Box>
      <Box padding={[6, 6, 8]} backgroundColor="accent.tertiary" flex={[2, 2, 2, 1]}>
        <VStack spacing={[8, 8, 12]} align="center">
          {props.logo}
          <VStack spacing={4}>
            <Heading size={3} align="center">
              {props.heading}
            </Heading>
            <Text align="center">{props.description}</Text>
            <VStack spacing={2} align="center">
              <Heading size={1}>{props.actualPrice}</Heading>
              <HStack spacing={6}>
                <Flag label={props.discount} data-testid={suffixTestId('discount', props)} />
                <Text strikethrough>{props.originalPrice}</Text>
              </HStack>
            </VStack>
          </VStack>
          <ButtonLink
            title={i18n.t('entity.banner.labels.purchase')}
            href={props.purchaseLink}
            target="_blank"
            rightIcon="navigation/arrow_forward"
            data-testid={suffixTestId('purchaseLink', props)}
          />
        </VStack>
      </Box>
    </Stack>
  </Box>
));
