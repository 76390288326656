import {Grid, Hide, Show, Text, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {useRef} from 'react';
import {useParams} from 'react-router-dom';

import {suffixTestId, TestIdProps, useBoolean} from 'shared';

import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import i18n from '../../../../i18n/i18n';
import {useNavigation} from '../../components/NavigationContext/NavigationContext';
import {VinDecoderCard} from '../../components/VinDecoderCard/VinDecoderCard';
import {GalleryImage} from './components/GalleryImage';
import {GalleryImageStack} from './components/GalleryImageStack';
import {filterOutNullishValues} from './utils/filterOutNullishValues';

export function PhotoGallery(props: TestIdProps) {
  const {decoder} = useGetVinDecoderData();
  const heading = i18n.t('entity.photoGallery.labels.photoGallery');
  const {registerSection} = useNavigation();
  const ref = registerSection(heading);
  const images = filterOutNullishValues(decoder?.data?.images);
  const [lightboxControls, {onOpen}] = useLightbox('default');
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';
  const loadedScreenImagesRef = useRef<Set<string>>(new Set());
  const [shouldLoadPrintImages, startLoadingPrintImages] = useBoolean(false);
  const screenImagesToDisplay = 4;

  const handleScreenImageLoad = (url: string) => {
    loadedScreenImagesRef.current.add(url);
    const allScreenImagesLoaded = loadedScreenImagesRef.current.size === screenImagesToDisplay;
    if (allScreenImagesLoaded) {
      startLoadingPrintImages();
    }
  };

  return (
    <VinDecoderCard
      icon="photoGallery"
      heading={heading}
      summary={i18n.t('entity.photoGallery.labels.photosCount', {count: images.length})}
      ref={ref}
      data-testid={suffixTestId('vinDecoderCard', props)}
    >
      <VStack spacing={[3, 6]}>
        <Text>{i18n.t('entity.photoGallery.labels.photosAreIllustrative')}</Text>
        <Show when={images.length > 0}>
          <GalleryImage
            index={0}
            url={images[0]?.url}
            shouldShowScreenImage
            shouldLoadPrintImage={shouldLoadPrintImages || isPrintForced}
            onClick={() => onOpen(0)}
            onLoad={() => handleScreenImageLoad(images[0].url)}
            data-testid={suffixTestId('galleryImage[0]', props)}
          />
        </Show>
        <Show when={images.length > 1}>
          <Grid columns={isPrintForced ? 2 : [2, 4, 4, 4, 2]} spacing={isPrintForced ? 6 : [3, 6]}>
            {images.slice(1).map((image, index) => (
              <GalleryImage
                key={image.id}
                index={index + 1}
                url={image.url}
                shouldShowScreenImage={index + 1 < screenImagesToDisplay}
                shouldLoadPrintImage={shouldLoadPrintImages || isPrintForced}
                onClick={() => onOpen(index + 1)}
                onLoad={() => handleScreenImageLoad(image.url)}
                data-testid={suffixTestId(`galleryImage[${index + 1}]`, props)}
              />
            ))}
            <Hide when={images.length <= screenImagesToDisplay || isPrintForced} inPrinter>
              <GalleryImageStack
                index={screenImagesToDisplay}
                url={images[screenImagesToDisplay]?.url}
                onClick={() => onOpen(screenImagesToDisplay)}
                imageStackLength={images.length - screenImagesToDisplay}
                data-testid={suffixTestId('galleryImageStack', props)}
              />
            </Hide>
          </Grid>
        </Show>
      </VStack>
      <Lightbox
        controls={lightboxControls}
        images={images}
        data-testid={suffixTestId('defaultLightbox', props)}
      />
    </VinDecoderCard>
  );
}
