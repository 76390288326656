import {Equipment} from '../types/Equipment';
import {isNullishDescription} from './isNullishDescription';

export const sortNullishDescriptionEquipment = (
  a: NonNullable<Equipment>,
  b: NonNullable<Equipment>
): number => {
  const aIsNullish = isNullishDescription(a);
  const bIsNullish = isNullishDescription(b);

  if (aIsNullish && bIsNullish) {
    return 0; // both items have nullish descriptions, keep original order
  } else if (aIsNullish) {
    return 1; // A has a nullish description, place it after B
  } else if (bIsNullish) {
    return -1; // B has a nullish description, place it after A
  }
  // both items have descriptions, sort alphabetically by description
  return a.description!.localeCompare(b.description!);
};
