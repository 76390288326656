import {
  Box,
  Center,
  Hide,
  HStack,
  Icon,
  Integer,
  Show,
  Spinner,
  ThemeIconKey,
} from 'platform/foundation';
import styled from 'styled-components';
import {match, Pattern} from 'ts-pattern';

import {always, isNil} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

type ButtonSize = 'default' | 'small';
type ButtonVariant = 'black' | 'orange' | 'outline';

export interface ButtonProps extends RequiredTestIdProps {
  title?: string;
  size?: ButtonSize;
  variant: ButtonVariant;
  isDisabled?: boolean;
  isLoading?: boolean;
  onClick?: VoidFunction;
  leftIcon?: ThemeIconKey;
  rightIcon?: ThemeIconKey;
}

export function Button(props: ButtonProps) {
  const isDisabled = Boolean(props.isDisabled || props.isLoading);

  return (
    <StyledButton
      role="button"
      $variant={props.variant}
      $size={props.size ?? 'default'}
      $isDisabled={isDisabled}
      $iconOnly={isNil(props.title)}
      data-testid={suffixTestId('buttonIcon', props)}
      onClick={props.onClick}
    >
      <HStack justify="center" spacing={2} align="center">
        <Hide when={isNil(props.leftIcon)}>
          <Icon value={props.leftIcon} size={5} />
        </Hide>
        <Hide when={isNil(props.title)}>
          <Center minHeight={5}>{props.title}</Center>
        </Hide>
        <Hide when={isNil(props.rightIcon)}>
          <Icon value={props.rightIcon} size={5} />
        </Hide>
      </HStack>
      <Show when={props.isLoading}>
        <Box position="absolute" top={0} right={0} bottom={0} left={0}>
          <HStack align="center" justify="center" height="100%">
            <Spinner color={props.variant === 'black' ? 'dark' : 'light'} />
          </HStack>
        </Box>
      </Show>
    </StyledButton>
  );
}

const getHorizontalPadding = (size: ButtonSize, iconOnly: boolean) =>
  match([size, iconOnly])
    .with(['default', Pattern.any], always(6 as Integer))
    .with(['small', false], always(4 as Integer))
    .with(['small', true], always(2 as Integer))
    .exhaustive();

// eslint-disable-next-line eag/no-css-property
const StyledButton = styled.button<{
  $variant: ButtonVariant;
  $isDisabled: boolean;
  $size: ButtonSize;
  $iconOnly?: boolean;
}>`
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  position: relative;
  padding: ${({theme, $size, $iconOnly}) =>
    `${theme.getSize($size === 'small' ? 2 : 3)} ${theme.getSize(getHorizontalPadding($size, !!$iconOnly))}`};
  border-radius: ${({theme}) => theme.radii.small};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  opacity: ${({$isDisabled}) => ($isDisabled ? 0.5 : 1)};
  cursor: ${({$isDisabled}) => ($isDisabled ? 'auto' : 'pointer')};
  pointer-events: ${({$isDisabled}) => ($isDisabled ? 'none' : 'all')};
  font-family: ${({theme}) => theme.fonts.body};
  font-size: ${({theme}) => theme.fontSizes.text.small};
  font-weight: ${({theme}) => theme.fontWeights.text.default};
  letter-spacing: 0;
  color: ${({$variant, theme}) =>
    match<ButtonVariant>($variant)
      .with('black', always(theme.colors.text.white))
      .with('orange', always(theme.colors.text.primary))
      .with('outline', always(theme.colors.text.primary))
      .exhaustive()};
  background-color: ${({$variant, theme}) =>
    match<ButtonVariant>($variant)
      .with('black', always(theme.colors.accent.complementary))
      .with('orange', always(theme.colors.accent.primary))
      .with('outline', always(theme.colors.general.transparent))
      .exhaustive()};
  border: ${({theme}) => `1px solid ${theme.colors.border.emphasisDefault}`};

  &:hover {
    color: ${({$variant, theme}) =>
      match<ButtonVariant>($variant)
        .with('black', always(theme.colors.text.white))
        .with('orange', always(theme.colors.text.primary))
        .with('outline', always(theme.colors.text.white))
        .exhaustive()};
    background-color: ${({$variant, theme}) =>
      match<ButtonVariant>($variant)
        .with('black', always(theme.colors.accent.darkGrey))
        .with('orange', always(theme.colors.accent.secondary))
        .with('outline', always(theme.colors.accent.complementary))
        .exhaustive()};
  }
`;
