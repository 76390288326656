import {Box, getSize, Heading, Integer, Show, useElementSize} from 'platform/foundation';
import {useFormatPercentage} from 'platform/locale';
import styled from 'styled-components';

import {ReactNode} from 'react';

import {isNotNil} from 'ramda';

import {RequiredTestIdProps, suffixTestId} from 'shared';

const MIN_PERCENT_FOR_LABEL_TO_BE_VISIBLE = 5;

interface PieChartProps extends RequiredTestIdProps {
  children: ReactNode;
  percentage: number;
  maxWidth?: Integer;
  type?: 'full' | 'half';
  hasLabel?: boolean;
}

export function PieChart(props: PieChartProps) {
  const [chartRef, chartHeight] = useElementSize<HTMLDivElement>();
  const [contentRef, contentHeight] = useElementSize<HTMLDivElement>();
  const formatPercentage = useFormatPercentage();
  const isHalf = props.type === 'half';
  const margin =
    isHalf && isNotNil(chartHeight) && isNotNil(contentHeight)
      ? -chartHeight / 2 + contentHeight / 2
      : 0;
  const minPercentForLabelToBeVisible = isHalf ? 0 : MIN_PERCENT_FOR_LABEL_TO_BE_VISIBLE;
  const anglePercent =
    props.percentage < 1 ? 0 : Math.max(minPercentForLabelToBeVisible, props.percentage);

  return (
    <ChartWrapper
      $maxWidth={props.maxWidth}
      $margin={margin}
      ref={chartRef}
      data-testid={suffixTestId('chartWrapper', props)}
    >
      <OuterEllipse $isHalf={isHalf}>
        <InnerEllipse />
      </OuterEllipse>
      <CircularProgress $anglePercent={anglePercent} $isHalf={isHalf} />
      <Show when={props.hasLabel && props.percentage > 0}>
        <PercentageWrapper $anglePercent={anglePercent} $isHalf={isHalf}>
          <Percentage
            $anglePercent={anglePercent}
            $isHalf={isHalf}
            data-testid={suffixTestId('percentageTextWrapper', props)}
          >
            <Heading size={3} color="white">
              {formatPercentage(props.percentage / 100, 'down')}
            </Heading>
          </Percentage>
        </PercentageWrapper>
      </Show>
      <ChildrenWrapper>
        <Box ref={contentRef} padding={isHalf ? 22 : 18}>
          {props.children}
        </Box>
      </ChildrenWrapper>
    </ChartWrapper>
  );
}

// Bottom of the half-pie chart should be bottom of the content, so we use a negative margin to achieve this
// eslint-disable-next-line eag/no-css-property
const ChartWrapper = styled.div<{
  $maxWidth?: Integer;
  $margin: number;
}>`
  position: relative;
  width: 100%;
  ${(props) => (isNotNil(props.$maxWidth) ? `max-width: ${getSize(props.$maxWidth)};` : '')}
  margin-bottom: ${(props) => props.$margin}px;
  overflow: hidden;
`;

const OuterEllipse = styled.div<{$isHalf: boolean}>`
  border: solid 2em ${(props) => props.theme.colors.accent.lightGrey};
  border-radius: 50%;
  padding: 1em;
  transform: ${(props) => (props.$isHalf ? 'rotate(-90deg)' : 'none')};
  mask: ${(props) =>
    props.$isHalf ? 'conic-gradient(white 50%, transparent 0%) border-box' : 'none'};
`;

const InnerEllipse = styled.div`
  aspect-ratio: 1;
  background: white;
  border-radius: 50%;
  stroke-width: 1px;
  stroke: ${(props) => props.theme.colors.general.stroke};
  filter: drop-shadow(0 0 40px rgb(0 0 0 / 10%));
`;

const CircularProgress = styled.div<{$anglePercent: number; $isHalf: boolean}>`
  position: absolute;
  inset: 0;
  border: solid 4em ${(props) => props.theme.colors.accent.primary};
  border-radius: 50%;
  transform: ${(props) => (props.$isHalf ? 'rotate(-90deg)' : 'none')};
  mask: conic-gradient(
      white ${(props) => props.$anglePercent * (props.$isHalf ? 0.5 : 1)}%,
      transparent 0%
    )
    border-box;
`;

const PercentageWrapper = styled.div<{$anglePercent: number; $isHalf: boolean}>`
  position: absolute;
  inset: 0;
  transform: rotate(${(props) => props.$anglePercent * (props.$isHalf ? 0.5 : 1) * 1.8}deg);
`;

const Percentage = styled.div<{$anglePercent: number; $isHalf: boolean}>`
  position: absolute;
  top: ${(props) => (props.$isHalf ? '50%' : '2em')};
  left: ${(props) => (props.$isHalf ? '2em' : '50%')};
  transform: translateX(-50%) translateY(-50%)
    rotate(-${(props) => props.$anglePercent * (props.$isHalf ? 0.5 : 1) * 1.8}deg);
`;

const ChildrenWrapper = styled.div`
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
