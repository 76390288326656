import {Center, Image} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import CebiaLogo from '../../../../assets/images/cebia_logo.svg';
import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import {FALLBACK_LANGUAGE} from '../../../../i18n/fallbackLanguage';
import i18n from '../../../../i18n/i18n';
import {useNavigation} from '../../components/NavigationContext/NavigationContext';
import {Banner} from './components/Banner';

const IMAGE_LANGUAGES = ['cs', 'pl', 'en'];

export function CebiaBanner(props: TestIdProps) {
  const {decoder} = useGetVinDecoderData();
  const navigationHeading = i18n.t('entity.bannerCebia.labels.navigationHeading');
  const {registerSection} = useNavigation();
  const ref = registerSection(navigationHeading);
  const {language} = useLocale();
  const imageLanguage = IMAGE_LANGUAGES.includes(language) ? language : FALLBACK_LANGUAGE;

  const link =
    'https://cz.cebia.com/?utm_source=affilbox&utm_medium=affiliate&utm_campaign=v1ndecoder&a_box=qrh7jjch&a_cha=v1ndecoder' +
    (isNotNil(decoder?.vin) ? `&vin=${decoder.vin}` : '');

  return (
    <Banner
      logo={<CebiaLogo />}
      heading={i18n.t('entity.bannerCebia.labels.heading')}
      description={i18n.t('entity.bannerCebia.labels.description')}
      actualPrice={i18n.t('entity.bannerCebia.labels.actualPrice')}
      originalPrice={i18n.t('entity.bannerCebia.labels.originalPrice')}
      discount={i18n.t('entity.bannerCebia.labels.discount')}
      purchaseLink={link}
      ref={ref}
      data-testid={suffixTestId('banner', props)}
    >
      <Center height="100%">
        <Image
          src={`../../../../assets/images/cebiaBanners/${imageLanguage}.png`}
          maxHeight={127}
          alt="banner"
        />
      </Center>
    </Banner>
  );
}
