import {Box, Clickable, Heading, HStack, Image, Show, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {useEffect} from 'react';

import {suffixTestId, TestIdProps} from 'shared';

import {Button} from '../../../../components/Button/Button';
import {useDownloadPDF} from '../../../../hooks/useDownloadPDF';
import i18n from '../../../../i18n/i18n';
import {featureFlags} from '../../../../utils/featureFlags';
import {useNavigation} from '../NavigationContext/NavigationContext';

interface MobileMenuProps extends TestIdProps {
  isOpen: boolean;
  onCloseClick: () => void;
  onShareClick: () => void;
}

export function MobileMenu(props: MobileMenuProps) {
  const {sections} = useNavigation();
  const {isOpen} = props;
  const {handleDownload, isPreparingDownload} = useDownloadPDF();

  // Disable scroll when menu is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Reset when component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <Show when={isOpen}>
      <Box
        backgroundColor="accent.primary"
        position="fixed"
        top={0}
        right={0}
        bottom={0}
        left={0}
        padding={5}
        zIndex="MOBILE_NAVIGATION"
      >
        <VStack height="100%" justify="space-between" spacing={5}>
          <HStack width="100%" justify="space-between" align="center">
            <Image
              src="./assets/images/logo.svg"
              alt="VIN Decoder"
              height={4}
              width="auto"
              data-testid={suffixTestId('logo', props)}
            />
            <Button
              leftIcon="navigation/close"
              variant="black"
              size="small"
              onClick={props.onCloseClick}
              data-testid={suffixTestId('closeMobileMenu', props)}
            />
          </HStack>
          <ScrollWithHiddenScrollbarInCypress>
            <VStack align="center" spacing={6}>
              {sections.map((section) => (
                <Clickable
                  key={section.title}
                  onClick={() => {
                    section.ref.current?.scrollIntoView({behavior: 'smooth'});
                    props.onCloseClick();
                  }}
                >
                  <Heading size={2}>{section.title}</Heading>
                </Clickable>
              ))}
            </VStack>
          </ScrollWithHiddenScrollbarInCypress>
          <VStack spacing={2}>
            <Button
              title={i18n.t('general.labels.share')}
              variant="outline"
              size="small"
              onClick={props.onShareClick}
              data-testid={suffixTestId('share', props)}
            />
            <Show whenFeatureEnabled={featureFlags.V1N_DOWNLOAD_BUTTON}>
              <Button
                title={i18n.t('general.labels.download')}
                variant="black"
                size="small"
                onClick={handleDownload}
                isLoading={isPreparingDownload}
                data-testid={suffixTestId('download', props)}
              />
            </Show>
          </VStack>
        </VStack>
      </Box>
    </Show>
  );
}

// Hide scrollbar, so snapshot tests won't fail (scrollbar doesn't look the same on different platforms)
const ScrollWithHiddenScrollbarInCypress = styled.div`
  overflow: auto;
  flex: 1;

  ${() =>
    // @ts-ignore
    window.Cypress &&
    `
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Edge */

      &::-webkit-scrollbar { /* Chrome, Safari and Opera */
        display: none;
      }
  `}
`;
