/**
 * To avoid merge conflicts, please add new feature flags to a random location (not the last line).
 */
export enum featureFlags {
  V1N_DOWNLOAD_BUTTON = 'v1n_download_button',
  V1N_CEBIA_BANNER = 'v1n_cebia_banner',
  V1N_PRICE_REPORT_BANNER = 'v1n_price_report_banner',
}
/**
 * To avoid merge conflicts, please add new feature flags to a random location (not the last line).
 */
