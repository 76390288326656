import {isNotNil} from 'ramda';

import {OMISSION_STRING} from 'shared';

const TRUNCATE_LENGTH = 300;

const truncate = (text?: string | null) =>
  isNotNil(text)
    ? `${text?.slice(0, TRUNCATE_LENGTH)}${text?.slice(TRUNCATE_LENGTH).split(' ')[0]}`
    : '';

export const isTruncatedWithoutSplittingWords = (text?: string | null) =>
  isNotNil(text) && text.length > truncate(text).length;

export const truncateWithoutSplittingWords = (text?: string | null) =>
  isTruncatedWithoutSplittingWords(text) ? `${truncate(text)}${OMISSION_STRING}` : text;
