import {
  Box,
  getValueByDevice,
  Heading,
  Show,
  Stack,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useParams} from 'react-router-dom';

import {isNotNil} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {AvoidBreakInside} from '../../../../../components/AvoidBreakInside/AvoidBreakInside';
import {PieChart} from '../../../../../components/PieChart/PieChart';
import i18n from '../../../../../i18n/i18n';

interface SpecialEquipmentChartProps extends TestIdProps {
  originalPrice: number;
  originalPriceWithoutVat?: number;
  totalPrice: number;
  totalPriceWithoutVat?: number;
  additionalEquipmentPrice: number;
  additionalEquipmentPriceWithoutVat?: number;
  currency: string;
}

export function SpecialEquipmentChart(props: SpecialEquipmentChartProps) {
  const formatCurrency = useFormatCurrency();
  const device = useDevice();
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';
  const additionalEquipmentPricePercentage =
    props.totalPrice !== 0 ? (props.additionalEquipmentPrice / props.totalPrice) * 100 : 0;

  return (
    <AvoidBreakInside>
      <Stack
        direction={['column', 'column', 'row']}
        align="center"
        justify="space-around"
        spacing={isPrintForced ? 14 : [6, 6, 14]}
      >
        <Box flex={1} width="100%" height="100%">
          <VStack align="center" spacing={2}>
            <Text size="small" alternative align="center">
              {i18n.t('entity.vehicleEquipment.labels.originalPrice')}
            </Text>
            <Heading size={2}>{formatCurrency(props.originalPrice, props.currency)}</Heading>
            <Show when={isNotNil(props.originalPriceWithoutVat)}>
              <Text size="xSmall" color="tertiary">
                {i18n.t('general.labels.priceWithoutVat', {
                  price: formatCurrency(props.originalPriceWithoutVat ?? 0, props.currency),
                })}
              </Text>
            </Show>
          </VStack>
        </Box>
        <Box flex={getValueByDevice(device, 2, 2, 2, 1.5)} width="100%" height="100%">
          <VStack align="center">
            <PieChart
              percentage={additionalEquipmentPricePercentage}
              maxWidth={84}
              hasLabel
              data-testid={suffixTestId('vehiclePriceChart', props)}
            >
              <VStack align="center" justify="center" spacing={2}>
                <Text size="small" alternative align="center">
                  {i18n.t('entity.vehicleEquipment.labels.totalPrice')}
                </Text>
                <Heading size={2}>{formatCurrency(props.totalPrice, props.currency)}</Heading>
                <Show when={isNotNil(props.totalPriceWithoutVat)}>
                  <Text size="xSmall" color="tertiary">
                    {i18n.t('general.labels.priceWithoutVat', {
                      price: formatCurrency(props.totalPriceWithoutVat ?? 0, props.currency),
                    })}
                  </Text>
                </Show>
              </VStack>
            </PieChart>
          </VStack>
        </Box>
        <Box flex={1} width="100%" height="100%">
          <VStack align="center" spacing={2}>
            <Text size="small" alternative align="center">
              {i18n.t('entity.vehicleEquipment.labels.specialEquipment')}
            </Text>
            <Heading size={2} color="accent">
              {`+${formatCurrency(props.additionalEquipmentPrice, props.currency)}`}
            </Heading>
            <Show when={isNotNil(props.additionalEquipmentPriceWithoutVat)}>
              <Text size="xSmall" color="tertiary">
                {i18n.t('general.labels.priceWithoutVat', {
                  price: formatCurrency(
                    props.additionalEquipmentPriceWithoutVat ?? 0,
                    props.currency
                  ),
                })}
              </Text>
            </Show>
          </VStack>
        </Box>
      </Stack>
    </AvoidBreakInside>
  );
}
