import {Box, Hide, HStack, Icon, Image, Text, ThemeIconKey, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {suffixTestId, TestIdProps} from 'shared';

import PriceReportLogo from '../../../../assets/images/price_report_logo.svg';
import {FALLBACK_LANGUAGE} from '../../../../i18n/fallbackLanguage';
import i18n from '../../../../i18n/i18n';
import {useNavigation} from '../../components/NavigationContext/NavigationContext';
import {Banner} from './components/Banner';

const IMAGE_LANGUAGES = ['cs', 'pl', 'en'];

export function PriceReportBanner(props: TestIdProps) {
  const navigationHeading = i18n.t('entity.bannerPriceReport.labels.navigationHeading');
  const {registerSection} = useNavigation();
  const ref = registerSection(navigationHeading);
  const {language} = useLocale();
  const imageLanguage = IMAGE_LANGUAGES.includes(language) ? language : FALLBACK_LANGUAGE;

  const priceReportAttributes: {label: string; icon: ThemeIconKey}[] = [
    {label: i18n.t('entity.bannerPriceReport.labels.realSellingPrice'), icon: 'label'},
    {label: i18n.t('entity.bannerPriceReport.labels.sellingTime'), icon: 'calendarAlt'},
    {label: i18n.t('entity.bannerPriceReport.labels.trends'), icon: 'trend'},
    {label: i18n.t('entity.bannerPriceReport.labels.supplyAndDemand'), icon: 'basket'},
    {label: i18n.t('entity.bannerPriceReport.labels.advertisedVehicles'), icon: 'list'},
    {label: i18n.t('entity.bannerPriceReport.labels.salesDetail'), icon: 'wallet'},
    {label: i18n.t('entity.bannerPriceReport.labels.virtualMarket'), icon: 'pieChart'},
    {label: i18n.t('entity.bannerPriceReport.labels.vehicleComparison'), icon: 'comparison'},
    {label: i18n.t('entity.bannerPriceReport.labels.vehicleSpecification'), icon: 'trophy'},
  ];

  return (
    <Banner
      logo={<PriceReportLogo />}
      heading={i18n.t('entity.bannerPriceReport.labels.pricingHeading')}
      description={i18n.t('entity.bannerPriceReport.labels.pricingDescription')}
      actualPrice={i18n.t('entity.bannerPriceReport.labels.actualPrice')}
      originalPrice={i18n.t('entity.bannerPriceReport.labels.originalPrice')}
      discount={i18n.t('entity.bannerPriceReport.labels.discount')}
      purchaseLink="https://carsdata.com/price-report"
      ref={ref}
      data-testid={suffixTestId('banner', props)}
    >
      <Box paddingVertical={[0, 0, 8]} paddingLeft={[0, 0, 8]}>
        <HStack spacing={10} height="100%" justify="center" align="center">
          <Hide onMobile onTablet>
            <VStack spacing={6}>
              {priceReportAttributes.map((attribute) => (
                <HStack key={attribute.icon} spacing={2} align="center">
                  <Icon value={attribute.icon} isNotFilled />
                  <Text size="small" noWrap>
                    {attribute.label}
                  </Text>
                </HStack>
              ))}
            </VStack>
          </Hide>
          <Image
            src={`../../../../assets/images/priceReportBanners/${imageLanguage}.png`}
            maxHeight={106}
            alt="banner"
          />
        </HStack>
      </Box>
    </Banner>
  );
}
