import {AdaptiveImage, Box, Clickable, Heading} from 'platform/foundation';
import styled from 'styled-components';

import {suffixTestId, TestIdProps} from 'shared';

import {makeStorageUrl} from '../../../../../utils/makeStorageUrl';
import {RATIO} from '../constants/ratio';

interface GalleryImageStackProps extends TestIdProps {
  index: number;
  url: string;
  imageStackLength: number;
  onClick: () => void;
}

export function GalleryImageStack(props: GalleryImageStackProps) {
  return (
    <Clickable onClick={props.onClick}>
      <Box position="relative">
        <AdaptiveImage
          url={props.url}
          makeUrl={makeStorageUrl}
          fit="cover"
          ratio={RATIO.toString()}
          data-testid={suffixTestId('image', props)}
        />
        <ImageStackOverlay>
          <Heading size={1} color="white">{`+${props.imageStackLength}`}</Heading>
        </ImageStackOverlay>
      </Box>
    </Clickable>
  );
}

const ImageStackOverlay = styled.div`
  position: absolute;
  inset: 0;
  background: ${({theme}) => theme.colors.general.overlay};
  display: flex;
  justify-content: center;
  align-items: center;
`;
