import {createApi} from '@reduxjs/toolkit/dist/query/react';

import {vinDecoderBaseQuery} from './baseQuery/vinDecoderBaseQuery';
import {V1NGetDecoderApiArg, V1NGetDecoderApiResponse} from './types/vinDecoderTypes';

export const vinDecoderApi = createApi({
  reducerPath: 'vinDecoder',
  baseQuery: vinDecoderBaseQuery,
  endpoints: (build) => ({
    v1NGetDecoder: build.query<V1NGetDecoderApiResponse, V1NGetDecoderApiArg>({
      query: (queryArg) => ({url: `/decoder/${queryArg.id}`}),
    }),
  }),
});

export const {useV1NGetDecoderQuery} = vinDecoderApi;
