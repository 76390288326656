import {Box, HStack, Spinner, Text, VStack} from 'platform/foundation';

import {ForwardedRef, forwardRef} from 'react';

import {PAGE_MAX_WIDTH} from '../../../../consts/pageMaxWidth';
import i18n from '../../../../i18n/i18n';

export const TranslationPendingBar = forwardRef((_props, ref: ForwardedRef<HTMLDivElement>) => (
  <Box position="sticky" top={0} zIndex="HEADER" ref={ref}>
    <Box backgroundColor="severity.warning" paddingVertical={5}>
      <VStack align="center">
        <Box width="100%" maxWidth={PAGE_MAX_WIDTH} paddingHorizontal={[5, 10, 10, 25, 10]}>
          <HStack spacing={4} align="center">
            <Spinner color="light" />
            <Text>
              <strong>{i18n.t('entity.translationStatus.labels.loadingTitle')}</strong>{' '}
              {i18n.t('entity.translationStatus.labels.loadingDescription')}
            </Text>
          </HStack>
        </Box>
      </VStack>
    </Box>
  </Box>
));
