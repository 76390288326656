import {Heading, Show, Text, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {useParams} from 'react-router-dom';

import {isNotNil, sort} from 'ramda';

import {suffixTestId, TestIdProps} from 'shared';

import {Alert} from '../../../../components/Alert/Alert';
import {useGetVinDecoderData} from '../../../../hooks/useGetVinDecoderData';
import i18n from '../../../../i18n/i18n';
import {EquipmentList} from '../../components/EquipmentList/EquipmentList';
import {useNavigation} from '../../components/NavigationContext/NavigationContext';
import {VinDecoderCard} from '../../components/VinDecoderCard/VinDecoderCard';
import {parseIntIfNotNil} from '../../utils/parseIntIfNotNil';
import {sortNullishDescriptionEquipment} from '../../utils/sortNullishDescriptionEquipment';
import {SpecialEquipmentChart} from './components/SpecialEquipmentChart';

const FALLBACK_CURRENCY = 'CZK';
const heading = i18n.t('entity.vehicleEquipment.labels.specialEquipment');

export function SpecialVehicleEquipment(props: TestIdProps) {
  const {decoder} = useGetVinDecoderData();
  const {registerSection} = useNavigation();
  const ref = registerSection(heading);
  const formatCurrency = useFormatCurrency();
  const isPrintForced = useParams<{param?: string}>().param === 'pdf';
  const isTranslationPending = decoder?.translationStatus === 'PENDING';

  const additionalEquipmentPrice = parseIntIfNotNil(decoder?.data?.additionalEquipmentPrice) ?? 0;
  const currency = decoder?.data?.currency ?? FALLBACK_CURRENCY;
  const price = formatCurrency(additionalEquipmentPrice, currency);

  const specialEquipment = sort(
    sortNullishDescriptionEquipment,
    (decoder?.data?.specialEquipment ?? []).filter(isNotNil)
  );

  return (
    <VinDecoderCard
      icon="ballot"
      heading={heading}
      summary={`+${price}`}
      ref={ref}
      data-testid={suffixTestId('vinDecoderCard', props)}
    >
      <VStack spacing={isPrintForced ? 8 : [6, 8]}>
        <Alert
          severity={isTranslationPending ? 'warning' : 'informational'}
          icon="gpt"
          iconColor="palettes.neutral.900.100"
          data-testid={suffixTestId('translatedFeaturesAlert', props)}
        >
          {isTranslationPending
            ? `${i18n.t('entity.translationStatus.labels.loadingTitle')} ${i18n.t('entity.translationStatus.labels.loadingDescription')}`
            : i18n.t('entity.vehicleEquipment.labels.alertSomeFeaturesTranslated')}
        </Alert>
        <VStack spacing={6}>
          <Show when={additionalEquipmentPrice > 0}>
            <Alert
              severity="accent"
              icon="shadowAdd"
              iconColor="accent.dark"
              data-testid={suffixTestId('additionalEquipmentSummary', props)}
            >
              <strong>
                {i18n.t('entity.vehicleEquipment.labels.items', {
                  count: specialEquipment.length,
                })}
              </strong>{' '}
              {i18n.t('entity.vehicleEquipment.labels.specialEquipmentPrice', {
                price,
              })}
            </Alert>
          </Show>
          <Text>{i18n.t('entity.vehicleEquipment.labels.specialEquipmentDescription')}</Text>
          <EquipmentList
            data={specialEquipment}
            currency={currency}
            flagSeverity="default"
            hasPriceHeadingShown
            isTranslationPending={isTranslationPending}
            data-testid={suffixTestId('specialEquipmentList', props)}
          />
          <Text size="small" color="secondary" align={isPrintForced ? 'left' : ['center', 'left']}>
            {i18n.t('entity.vehicleEquipment.labels.pricesIncludesVAT')}
          </Text>
        </VStack>
        <VStack spacing={6}>
          <Heading size={3} align={isPrintForced ? 'left' : ['center', 'left']}>
            {i18n.t('entity.vehicleEquipment.labels.originalPriceHeading')}
          </Heading>
          <Text align={isPrintForced ? 'left' : ['center', 'left']}>
            {i18n.t('entity.vehicleEquipment.labels.originalPriceDescription')}
          </Text>
        </VStack>
        <SpecialEquipmentChart
          originalPrice={parseIntIfNotNil(decoder?.data?.originalPriceNet) ?? 0}
          totalPrice={parseIntIfNotNil(decoder?.data?.totalVehiclePrice) ?? 0}
          additionalEquipmentPrice={additionalEquipmentPrice}
          currency={currency}
        />
      </VStack>
    </VinDecoderCard>
  );
}
